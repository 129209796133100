import FSCKPopcorn from '../media/FSCKPopcorn';
import './FSCK25.css';

function FSCK25() {
    return (
        <div>
            <div className="FSCK25Image">
                <FSCKPopcorn />
            </div>
        </div>
    );
}

export default FSCK25;