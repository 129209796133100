import './NotFound.css';

function NotFound() {
    return (
        <div className="NotFound">
            <h1 className="NotFoundTitle">404 Not Found</h1>
        </div>
    );
}

export default NotFound;